import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
//import { CookieService } from 'ngx-cookie-service';
import { NavbarModule } from './shared/navbar/navbar.module';
import { AuthInterceptor } from './shared/interceptor.service';
import { FooterModule } from '../app/shared/footer/footer.module';
import { Papa } from 'ngx-papaparse';
import { MDBBootstrapModule  } from 'angular-bootstrap-md';
import { MatDialogModule } from '@angular/material/dialog';
//import { MatFormFieldModule } from '@angular/material/form-field';
//import { MatInputModule } from '@angular/material/input';
// import { AngularEditorModule } from '@kolkov/angular-editor';
//import {MatAutocompleteModule} from '@angular/material/autocomplete';
//import { MatInputModule } from '@angular/material/input';
//import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NavbarModule,
    FooterModule,
    /*Este import lo pongo aquí por que tengo un servicio
     que se llama OptionDialogService y lo que hace es poder llamar
     a una ventana de mensaje modal con opciones desde cualquier lugar
     del sistema, entonces, como los servicios no tienen un módulo específico,
     hay que específicamente, los módulos que se quieran utilizar inyectados
     por servicios, hay que declararlos aquí.*/
    MatDialogModule,
   /* MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,*/
    MDBBootstrapModule.forRoot(),
    // AngularEditorModule
  ],
  providers: [
    //CookieService,
    Papa,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { ActivatedRoute, Router, NavigationStart, RouterEvent } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'OfiRep - Ubicuo System';
  rutaRelativa: string;
  response: string;
  isDisplayNavbar = false;
  constructor(private authService: AuthService, private router: Router) {
    this.canDisplayNavbar();
  }

  async canDisplayNavbar() {

      this.router.events.subscribe(async (event: RouterEvent) => {
        if (event instanceof NavigationStart) {
          this.rutaRelativa = event.url;

          const response = await this.authService.isAuthenticated(this.rutaRelativa);

          if (response) {
            this.isDisplayNavbar = true;
          }
          }
      });
    }

}


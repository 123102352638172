import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
//import { CookieService } from 'ngx-cookie-service';
import { URL_API } from 'src/environments/environment';
import { CommonService } from '../shared/common.service';
import { take, share } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  someDataObservable: Observable<any>;

  constructor(
              private http: HttpClient,
              //private cookieService: CookieService,
              private commonService: CommonService,
              ) {}

  doLogin(usuario: string, password: string) {
    const body = new FormData();
    body.append('user', usuario);
    body.append('pass', password);
    return this.http.post(`${URL_API}/login.php`, body);
  }

  public async isAuthenticated(url: string): Promise<boolean> {

    let rutaSeleccionada: string;

    const promise = new Promise<boolean>((resolve, reject) => {
      rutaSeleccionada = url.substring(1);
      rutaSeleccionada = rutaSeleccionada.split('/')[0];
      this.http.get(`${URL_API}/check_usuarios.php?ruta=${ rutaSeleccionada }`,  { headers: this.commonService.getHeaders() } )
      .pipe(share()).subscribe((response: boolean) => {
        resolve(response);
      });
    });

    return promise;

  }

  isAdmin() {
    return this.http.get(`${URL_API}/check_rol.php`,  { headers: this.commonService.getHeaders() } );
  }

  isOperador(): Promise<boolean> {

    const promise = new Promise<boolean>(async (resolve, reject) => {
      const rol = await this.http.get(`${URL_API}/check_rol_operador.php`,  { headers: this.commonService.getHeaders() } ).toPromise();
      const isOperador = rol === 'Operador' ? true : false;
      resolve(isOperador);
    });

    return promise;
  }

  /*public getUsername() {
    const token = this.cookieService.get('token');
  }*/

  doLogout() {
    const body = new FormData();
    const usuario = localStorage.getItem('usuario');
    body.append('user', usuario);
    localStorage.clear();
    return this.http.post(`${URL_API}/logout.php`, body);
  }

  resetPassword(formularioCorreo) {
    const body = JSON.stringify(formularioCorreo);
    return this.http.post(`${URL_API}/olvidar_pwd.php`, body, {headers: this.commonService.getHeaders() });

  }

  checkPassToken(tokenPasswd: string) {

    const headers: any = new Headers({
      'Content-Type': 'application/json',
    });

    const body = JSON.stringify({ token: tokenPasswd });
    return this.http.post(`${URL_API}/check_token_passwd.php`, body, { headers });
  }

  generateNewPass(tokenPasswd: string, pass: string, confirmPass: string) {
    const headers: any = new Headers({
      'Content-Type': 'application/json',
    });
    const body = JSON.stringify({ token_passwd: tokenPasswd, pass, confirm_pass: confirmPass });

    return this.http.post(`${URL_API}/reset_pass.php`, body, { headers });

  }

  generateLogLogin() {
    return this.http.get(`${URL_API}/generate_log_login.php`, { headers: this.commonService.getHeaders() });
  }
}

export const environment = {
  production: false
};

const URL_BASE = 'https://ubicuobackendpre.ofirep.com';
const URL_API = `${URL_BASE}/api/private`;
const URL_IMG = `${URL_BASE}/api/private/`;
const SOCKET_ENDPOINT = `https://appsocketpre.ofirep.com`;
const URL_REGEDIT = `${URL_BASE}/api/public/app_regedit/app_regedit.zip`;

const SNACKBAR_MAX_SHOW_TIME_MS = 5000;

export {
  URL_BASE,
  URL_API,
  URL_IMG,
  SOCKET_ENDPOINT,
  URL_REGEDIT,
  SNACKBAR_MAX_SHOW_TIME_MS
}

<div class="herramientas">
    <a class="logo">
        <img src="assets/icono-gm.png" onerror="this.style.display='none'">
    </a>

    <div class="navbar">
        <div class="vistaactual">{{grupo}} > {{vista}}</div>
        <div class="usuario">
            <div class="todo" (click)="goPerfil()">
                <div class="icono">
                    <mat-icon>person</mat-icon>
                </div>
                <div class="subcontenido">{{ username }}</div>
            </div>
            <div (click)="salir()" class="todo">
                <div class="icono">
                    <mat-icon>exit_to_app</mat-icon>
                </div>
                <div class="subcontenido">Salir</div>
            </div>
        </div>
    </div>
</div>

<div class="menu_opciones" id="navbarNavDropdown">
    <ul class="navbar-nav ulpermenu">
        <li *ngFor="let grupo of menu">
            <button class="opcionesNav" mat-button [matMenuTriggerFor]="app"
                (click)="almacenarGrupo(grupo.grupo)">{{grupo.grupo}}</button>
            <mat-menu #app="matMenu">
                <button mat-menu-item id="{{opcion.opcion}}" *ngFor="let opcion of grupo.opciones"
                    [matTooltip]="opcion.texto_tooltip" (click)="actualizarVistaNavbar(opcion.opcion)"
                    [matTooltipPosition]="'right'" routerLink="../{{opcion.accion}}" href="#">
                    {{opcion.opcion}}</button>
            </mat-menu>
        </li>
    </ul>
</div>
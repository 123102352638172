import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URL_API } from 'src/environments/environment';
import { CommonService } from '../shared/common.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  headers: HttpHeaders;

  constructor(private http: HttpClient, private commonService: CommonService) {
  }

  getMenu() {
    return this.http.get(`${URL_API}/navegacion.php`, { headers: this.commonService.getHeaders() });
  }
}

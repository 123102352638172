import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService as AuthGuard } from './guards/auth.guard';
//import { LopdRulesCustomerConfirmationViewerComponent } from './lopd-rules-customer-confirmation-viewer/lopd-rules-customer-confirmation-viewer.component';

const routes: Routes = [
  {
     path: 'home',
     loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'grupos',
    loadChildren: () => import('./grupos/grupos.module').then(m => m.GruposModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'opciones',
    loadChildren: () => import('./opciones/opciones.module').then(m => m.OpcionesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'roles',
    loadChildren: () => import('./roles/roles.module').then(m => m.RolesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'roles-menu',
    loadChildren: () => import('./roles-menu/roles-menu.module').then(m => m.RolesMenuModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'usuarios',
    loadChildren: () => import('./usuarios/usuarios.module').then(m => m.UsuariosModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'perfil',
    loadChildren: () => import('./perfil/perfil.module').then(m => m.PerfilModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'lopd',
    loadChildren: () => import('./lopd/lopd.module').then(m => m.LopdModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'lopd-confirmation-customer-viewer',
    loadChildren: () => import('./lopd-rules-customer-confirmation-viewer/lopd-rules-customer-confirmation-viewer.module').then(m => m.LopdRulesCustomerConfirmationViewerModule)
  },
  {
    path: 'logs',
    loadChildren: () => import('./logs/logs.module').then(m => m.LogsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'inicio',
    loadChildren: () => import('./inicio/inicio.module').then(m => m.InicioModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tipos-servicio',
    loadChildren: () => import('./tipos-servicio/tipos-servicio.module').then(m => m.TiposServicioModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'servicios',
    loadChildren: () => import('./servicios/servicios.module').then(m => m.ServiciosModule),
    canActivate: [AuthGuard],
  },
  { path: 'extensiones',
    loadChildren: () => import('./extensiones/extensiones.module').then(m => m.ExtensionesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'incidencias',
    loadChildren: () => import('./incidencias/incidencias.module').then(m => m.IncidenciasModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'historico-llamadas',
    loadChildren: () => import('./historico-llamadas/historico-llamadas.module').then(m => m.HistoricoLlamadasModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'llamadas',
    loadChildren: () => import('./llamadas/llamadas.module').then(m => m.LlamadasModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tipos-motivo',
    loadChildren: () => import('./tipos-motivo/tipos-motivo.module').then(m => m.TiposMotivoModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'abonados',
    loadChildren: () => import('./abonados/abonados.module').then(m => m.AbonadosModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cliente',
    loadChildren: () => import('./cliente/cliente.module').then(m => m.ClienteModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'footer',
    loadChildren: () => import('./shared/footer/footer.module').then(m => m.FooterModule)
  },
  {
    path: 'verFotos',
    loadChildren: () => import('./ver-fotos/ver-fotos.module').then(m => m.VerFotosModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'configuracion',
    loadChildren: () => import('./configuracion/configuracion.module').then(m => m.ConfiguracionModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tablet',
    loadChildren: () => import('./tablet/tablet.module').then(m => m.TabletModule)
  },
  {
    path: 'parte-viajero',
    loadChildren: () => import('./parte-viajero/parte-viajero.module').then(m => m.ParteViajeroModule)
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home'
  },
  {
    path: '**',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
